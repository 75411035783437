.features {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 5rem 0;
    align-items: center;
    justify-content: center;
}

.features__container {
    max-width: 1200px;
    display: flex;
    justify-content: right;
}

.features__image {
    flex: 1;
    max-width: 50vh;
}

.features__image img {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.features__image#layout {
    display: none;
}

.features__text {
    padding: 5rem 2rem;
    flex: 1.5;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.features__text p {
    font-family: var(--font-family);
    color: var(--color-bl7);
    font-size: 24px;
    line-height: 40px;
}

.features__text h1 {
    margin-bottom: 1rem;
    font-size: 50px;
}

.features__text#justify_r {
    justify-content: right;
    text-align: right;
}

@media screen and (max-width: 650px) {
    .features__container {
        flex-direction: column;
    }

    .features__text {
        text-align: center;
        padding: 2rem;
    }
    
    .features__text h1 {
        margin-bottom: 1rem;
        font-size: 2em;
    }

    .features__text#justify_r {
        justify-content: center;
        text-align: center;
    }

    .features__image#layout {
        display: flex;
    }

    .features__image#layout_ {
        display: none;
    }
}