

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
       url('../src/assets/fonts/calibri.ttf') format('ttf');
}

:root {
  --font-family: 'Calibri', sans-serif;

  --text-XS: 14px;
  --text-S: 16px;
  --text-M: 18px;
  --text-L: 20px;
  --text-XL: 22px;
  --text-XXL: 24px;
  --text-title: 45px;

  --gradient-text: linear-gradient(89.97deg, #6569D8 1.84%, #FF5F5D 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #13678A -13.86%, #FF5F5D 99.55%);
  
  --color-bg: #121212;
  --color-bg-light: #FFFFFF;
  --color-primary: #6569D8;
  --color-secondary: #FF5F5D;
  --color-accent: #59D7FD;
  --color-text: #4C5264; /*#81AFDD */
  --color-input: #BCC5D3;

  --color-wt: rgba(255,255,250,1);
  --color-wt7: rgba(255,255,250,0.75);
  --color-wt5: rgba(255,255,250,0.50);
  --color-wt2: rgba(255,255,250,0.25);
  --color-bl1: rgba(0,0,0,0.1);
  --color-bl2: rgba(0,0,0,0.25);
  --color-bl5: rgba(0,0,0,0.5);
  --color-bl7: rgba(0,0,0,0.75);
  --color-bl8: rgba(0,0,0,0.8);
  --color-bl9: rgba(0,0,0,0.9);
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-altext: #AE67FA ;
  --color-subtext: #FF8A71;
  --color-orange: #ff4820;

  --color-pa-01: #012030;
  --color-pa-02: #6569D8; /* replace pb-02 */
  --color-pa-03: #45C4B0;
  --color-pa-04: #9AEBA3;
  --color-pa-05: #DAFDBA;

  --color-pb-01: #FF5F5D;
  --color-pb-01h: #FF7B7B;
  /* --color-pb-02: #3F7C85; */
  --color-pb-03: #59D7FD;
  --color-pb-04: #72F2EB;
  
}