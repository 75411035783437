.header {
    padding: 2rem 6rem;
}

.header__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    margin: 0 5rem ;
}

.header__subcontent{
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    margin: 0 20rem ;
}

.header__content h1 {
    text-align: center;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 96px;
    line-height: 85px;
    letter-spacing: -0.04em;
}

.header__subcontent h1 {
    padding-top: 4rem;
    text-align: center;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 42px;
    letter-spacing: -0.04em;
}

.header__subcontent p{
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 28px;
    justify-content: left;
    align-items: left;
    color: var(--color-text);
    margin: 1.5rem 5rem -1.5rem 5rem;
}

.header__content p {
    text-align: center;
    font-family: var(--font-family);
    font-size: 22px;
    line-height: 38px;
    justify-content: center;
    align-items: center;
    color: var(--color-text);
    margin: 1.5rem 5rem 0 5rem;
}

.header__content-input {
    width: 60%;
    margin: 2rem 0 1rem;
    display: flex;
    justify-content: center;
}

.header__content-input button {
    flex: 0.5;
    width: 50%;
    min-height: 60px;

    background: var(--color-pa-02);
    border-radius: 5px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-wt7);
    padding: 0 1rem;
    cursor: pointer;
    outline: none;
    border: none;
}

.header__content-input button:hover,
.header__content-input button:active {
    filter: brightness(125%);
}

.header__content-people {
    width: 100%;
    display: none; /* was flex */
    justify-content: flex-start;
    align-items: center;
}

.header__content-people p {
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--color-bl7);
}

.header_image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_image img {
    width: 100%;
    max-width: 1280px;
    height: 100%;
}

.header_image #hero {
    display: none;
}

.header_image #hero_m {
    display: none;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }
    .header__subcontent{
        margin: 0 0 3rem;
    }
    .header__content {
        margin: 0 0 3rem;
    }

    .header__subcontent p {
        margin: 1.5rem 0 0 0;
    }

    .header__content p {
        margin: 1.5rem 0 0 0;
    }

    .header__content h1 {
        font-weight: 700;
    }

    .header__content-input {
        width: 80%;
    }

    .header_image #hero {
        display: flex;
    }

    .header_image #hero_d {
        display: none;
    }

    .header_image #hero_m {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .header {
        padding: 1rem 2rem ;
    }

    .header h1 {
        font-size: 42px;
        line-height: 50px;
    }

    .header p {
        font-size: 16px;
        line-height: 26px;
    }

    .header__content h1 {
        font-weight: 700;
    }

    .header__content p {
        font-size: 20px;
        line-height: 32px;
        margin: 1.5rem 1rem 0 1rem;    
    }

    .header__subcontent p{
        margin: 1.5rem 1rem -3rem 1rem;
    }

    .header__content-input {
        width: 100%;
    }

    .header__content-people {
        display: none;
    }

    .header__content-input input,
    .header__content-input button {
        font-size: 16px;
        line-height: 18px;
    }
    .header_image img {
        margin-top: 0rem;
    }

    .header_image #hero {
        display: none;
    }

    .header_image #hero_d {
        display: none;
    }
    
    .header_image #hero_m {
        display: flex;
    }

}