.whatis {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    display: flex;
    background: var(--color-bg);
    padding: 4rem 6rem;
    margin: 5rem 0;
}

.whatis__content--mobile {
    align-items:center;
    max-width: 100vh;
    display: none;
}

.whatis__content--desktop {
    align-items:center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0 2rem;
}

.whatis__container {
    display: flex;
    flex: 1;
    height: 33vh;
}

.whatis__content-image {
    height: 33vh;
    flex: 1;
}

.whatis__content-image img {
    height:100%;
    width: 100%;
}

.whatis__content-desc {
    padding-top: 5rem;
    flex: 1.5;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.whatis__content-desc h1 {
    margin-bottom: 1rem;
    font-size: 50px;
}

.whatis__content-desc p {
    color: var(--color-wt7);
    max-width: 700px;
    font-size: 28px;
    line-height: 42px;
}

.whatis__content-card {
    justify-content:center;
    align-items:center;
    min-height: 30rem;
    margin: 1rem;
    width: 100%;
    flex: 1;
}

.whatis__content-card img {
    height: 100%;
    width: 100%;
}

.whatis__content-card h1 {
    text-align: center;
    color: var(--color-pb-01);
}

.whatis__content-card p {
    font-family: var(--font-family);
    color: var(--color-wt);
    font-size: 22px;
    line-height: 32px;
    max-width: 90%;
    text-align: center;
    padding-top: 20px;
    margin: auto;
}

@media screen and (max-width: 1050px) {
    .whatis {
        margin: 0;
        margin-top: 5rem;
    }

    .whatis__content-desc {
        padding-top: 5rem;
    }
}

@media screen and (max-width: 650px) {
    .whatis {
        margin-top: 5rem;
        padding: 5rem 0;
    }

    .whatis__content--mobile {
        display: flex;
        margin: 0 2rem;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .whatis__content-card p {
        max-width: 100%;
        font-size: 24px;
        line-height: 40px;
    }

    .whatis__content--desktop {
        display: none;
    }
}