.footer {
    display: flex;
    flex-direction: column;
    background: var(--color-bg);
    justify-content: center;
    align-items: center;
    
}

.footer__section_top {
    display: flex;
    flex: 1;
    width: 100%;
}

.footer__section_top iframe {
    min-height: 25vh;
    width: 100vw;
}

.footer__section_middle {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--color-input);
    width: 100%;
    max-width: 1030px;
    padding: 4rem 0rem;
    border-bottom: 1px solid var(--color-text);
    transform: all 1s linear;
}

.footer__section_middle h1 {
    padding: 1rem;
}
.footer__section_middle p {
    padding: 1rem;
    font-size: var(--text-M);
    line-height: 30px;
}

.footer__section_bottom {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 0rem 5rem;
    color: var(--color-input);
}

.footer__section_bottom p {
    font-family: var(--font-family);
    font-size: var(--text-S);
    line-height: var(--text-XXL);
}

.footer__bottom_1 {
    border-bottom: 1px solid var(--color-text);
    flex: 2;
    padding: 1.5rem 2rem;
}

.footer__bottom_1 img {
    height: 40px;
}

.footer__bottom_1 img#flag {
    height: 15px;
    margin: 0 10px;
}

.footer__bottom_1 p {
    color: var(--color-input);
    font-family: var(--font-family);
    font-size: var(--text-M);
    line-height: 35px;
}

.footer__bottom_2 {
    border-bottom: 1px solid var(--color-text);
    flex: 1;
    padding: 2rem;
}

.footer__bottom_2 a#link_about {
    color: var(--color-secondary);
}

.footer__bottom_3 {
    border-bottom: 1px solid var(--color-text);
    flex: 1;
    padding: 0 2rem;
    text-align: right;
}

.footer__bottom_3 p {
    font-size: var(--text-XL);
}


.footer__bottom_2 a:hover,
.footer__bottom_3 a:hover {
    color: var(--color-secondary);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.footer__icon-arrow {
    padding-top: 5px;
}


.footer__icon {
    font-size: 32px;
    margin-top: 2rem;
    margin-left: 1rem;
}

.footer__icon#insta {
    color: var(--color-secondary);
}

.footer__icon#twit {
    color: var(--color-accent);
}

.footer__info {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1200px;
    color: var(--color-text);
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 5rem;
}

.footer__info p#copy_mobile {
    display: none;
}

@media screen and (max-width: 1050px){
    .footer__section_middle {
        max-width: 850px;
        padding: 4rem 1rem;
        
    }

}

@media screen and (max-width: 650px) {
    .footer{
        height: 100%;
        
    }
    .footer__section_top {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer__section_top iframe {
        min-height: 35rem;
        width: 96vw;
    }
    
    .footer__section_middle {
        padding: 3rem 2rem;
    }

    .footer__section_bottom {
        flex-direction: column;
        height: auto;
        padding: 0 1rem;
    }
    .footer__bottom_1 {
        border: 0;
        flex: 1;
    }
    .footer__bottom_2{
        border: 0;
        flex: 1;
    }
    .footer__bottom_3 {
        border-bottom: 1px solid var(--color-text);
        flex: 1;   
        text-align: left;
        padding-bottom: 5rem;
    }

    .footer__icon {
        font-size: 42px;
        margin-top: 2rem;
        margin-left: 0;
        margin-right: 1rem;
    }

    .footer__info {
        padding-bottom: 10rem;
    }

    .footer__info p#copy_desktop {
        display: none;
    }
    
    .footer__info p#copy_mobile {
        display: flex;
    }
    
}