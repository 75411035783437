* {
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg-light);
  font-family: var(--font-family);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  z-index: -5;
  background: radial-gradient(circle at -10% 0%, #718291 0%, rgba(255, 255, 255, 0) 40%);
  background: -webkit-radial-gradient(circle at -10% 0%, #718291 0%, rgba(255, 255, 255, 0) 40%);
}

.gradient__dark {
  background:radial-gradient(circle at 0% 100%, #202020 0%, #121212 75%);
  background:-webkit-radial-gradient(circle at 0% 100%, #202020 0%, #121212 100%);
  background:-moz-radial-gradient(circle at 0% 100%, #202020 0%, #121212 100%);
  background:-o-radial-gradient(circle at 0% 100%, #202020 0%, #121212 100%);
  background:-ms-radial-gradient(circle at 0% 100%, #202020 0%, #121212 100%);
}

.justify_right {
    justify-content: right;
    text-align: right;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
              transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
  
  
  



@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}
