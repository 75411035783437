.cta {
    display: flex;
    background: var(--color-bg);
    justify-content: center;
    align-items: center;
    min-height: 40vh;
}

.cta__section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cta__section-title {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    flex-direction: column;
}

.cta__section-title h1 {
    text-align: center;
    margin: 0 auto;
    font-family: var(--font-family);
    font-weight: 200px;
    font-size: 70px;
    line-height: 85px;
    margin-bottom: 1rem;
}

.cta__section-title p {
    text-align: center;
    color: var(--color-input);
    font-size: 26px;
    line-height: 42px;
}

.cta__section-form {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.cta__section-form input {
    background: var(--color-bl5);
    color: var(--color-input);
    font-family: var(--font-family);
    border-radius: 5px 0px 0px 5px;
    padding: 1rem 2rem;
    outline: 0;
    border: 0;
    font-size: 26px;
    line-height: 32px;
    width: 50%;
}

.cta__section-form button {
    flex: 0.5;
    max-width: 200px;
    min-height: 60px;

    background: var(--color-primary);
    border-radius: 0px 5px 5px 0px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-wt7);
    padding: 0 1rem;
    cursor: pointer;
    outline: none;
    border: none;
}

.cta__section-form button:hover,
.cta__section-form button:active {
    filter: brightness(125%);
    background-color: #FF5F5D;
}

.cta__section-info {
    padding: 1rem 0;
    font-size: 18px;
    line-height: 32px;
    color: var(--color-wt2);
}

.cta__section-info p#info_mobile {
    display: none;
}


@media screen and (max-width: 650px) {

    .cta {
        height: 50vh;
    }

    .cta__section-title h1 {
        font-size: 50px;
    }

    .cta__section-title p {
        font-size: 24px;
    }

    .cta__section-info p#info_desktop {
        display: none;
    }
    
    .cta__section-info p#info_mobile {
        display: flex;
    }

}