.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 3rem 2rem;

}

.navbar__links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar__links-logo {
    margin-right: 2rem;
}

.navbar__links-logo img {
    width: 100px;
    height: 50px;
}

.navbar__links-container {
    display: flex;
    flex-direction: row;
}

.navbar__sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar__links-container p,
.navbar__sign p {
    color: var(--color-bl8);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar__links-container a:hover,
.navbar__sign a:hover {
    color: #fff;
}


.navbar__menu-container p {
    color: var(--color-pb-05);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar__menu-container a:hover {
    color: #fff; 
}
.navbar__menu-container a:active {
    color: #fff;
}


.navbar__sign button,
.navbar__menu-container button {
    padding: 0.5rem 1rem;
    background: var(--color-pa-02);
    border-radius: 5px;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar__sign button:hover,
.navbar__menu-container button:hover {
    filter: brightness(125%);
}

.navbar__menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar__menu svg {
    cursor: pointer;
}

.navbar__menu-container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-bl8);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 15px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navbar__menu-container p {
    margin: 1rem 0;
}

.navbar__menu-container-links {
    color: #fff;
}

.navbar__menu-container-sign {
    display: none;
}


@media screen and (max-width: 1050px) {
    .navbar__links-container{
        display: none;
    }
    .navbar__menu{
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 1rem;
    }

    .navbar__sign {
        display: none;
    }

    .navbar__links-logo img {
        width: 80px;
        height: 40px;
    }

    .navbar__menu-container {
        top: 20px;
    }

    .navbar__menu-container-sign {
        display: block;
    }
}