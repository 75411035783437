.brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.brand div {
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand img {
    width: 200px;
    margin: 0 1rem;
}

@media screen and (max-width: 1050px) {
    .brand img {
        width: 140px;
        margin: 0 1rem;
       
    }
}

@media screen and (max-width: 650px) {
    .brand div {
        flex-direction: column;
    }

    .brand img {
        width: 180px;
        margin: 0.2rem;
    }
}