$colors:(
    c01: #BCC5D3,
    c02: #6569D8,
    c03: #59D7FD,
    c04: #4C5264,
    c05: #FF5F5D,
    c06: #718291, //bg_gradient
    c06b: rgba(255, 255, 255, 0), //bg_gradient white
);

@function clr($color_code) {
    @return map-get($colors, $color_code)
}


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg-light);
    font-family: var(--font-family);
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    z-index: -5;
    background:radial-gradient(circle at -10% 0%, clr(c06) 0%, clr(c06b) 40%);
    background:-o-radial-gradient(circle at -10% 0%, clr(c06) 0%, clr(c06b) 40%);
    background:-ms-radial-gradient(circle at -10% 0%, clr(c06) 0%, clr(c06b) 40%);
    background:-moz-radial-gradient(circle at -10% 0%, clr(c06) 0%, clr(c06b) 40%); 
    background:-webkit-radial-gradient(circle at -10% 0%, clr(c06) 0%, clr(c06b) 40%);
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }      
  }

  @media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }      
  }