.faq {
    display: flex;
    justify-content: flex-start;
    min-height: 50vh;
    padding: 3rem 2rem;
}

.faq__section {

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 50%;
    margin: 0 auto;

}

.faq__section h1 {
    font-family: var(--font-family);
    font-size: var(--text-title);
    padding: 1rem 0;
}

.faq__section h1#faq_mobile {
    display: none;
}

.faq__section p#faq_mobile {
    display: none;
}

.faq__section p#faq_desktop {
    font-size: var(--text-L);
    padding-bottom: 2rem;
    text-align: center;
}

.faq__section_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    transition: height 1s;
}

.faq__section-question {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;
    min-height: 60px;
}

.faq__section-question p {
    font-family: var(--font-family);
    font-size: var(--text-XXL);
    line-height: 42px;
    font-weight: 700;
    cursor: pointer;
}

.faq__section-question p#q_true {
    color: var(--color-input); 
}

.faq__section-question p#q_false {
    text-transform: uppercase;
}

.faq__section-answer {
    border-top: 1px solid var(--color-input);
    justify-content: center;
    min-height: 1px;
    width: 100%;
    padding: 2rem;
}

.faq__section-answer p {
    font-family: var(--font-family);
    font-size: var(--text-M);
    line-height: 36px;
}

@media screen and (max-width: 1050px) {
    .faq__section {
        max-width: 75%;
    }
}
@media screen and (max-width: 650px) {

    .faq__section {
        max-width: 100%;
    }

    .faq__section h1#faq_desktop {
        display: none;
    }

    .faq__section h1#faq_mobile {
        display: flex;
    }

    .faq__section p#faq_desktop {
        display: none;
    }
    
    .faq__section p#faq_mobile {
        text-align: center;
        font-size: var(--text-S);
        padding-bottom: 2rem;
        display: flex;
    }

    .faq__section-question p {
        font-size: var(--text-L);
        line-height: 32px;
    }

    .faq__section-answer p {
        font-size: var(--text-S);
    }
}